<template>
	<div style="padding-top: 20px;" id="majorLog">
		<div class="contents">
			<div class="table">
				<div class="font_blod" style="color: #484848;padding-bottom: 15px;">共{{List.length}}条数据</div>
				<el-table ref="multipleTable" :data="List" tooltip-effect="dark" style="width: 100%;height: 70vh;">
					<!-- <el-table-column type="selection" width="55" align="center"></el-table-column> -->
					<el-table-column type="index" label="序号" width="70" align="center"></el-table-column>
					<el-table-column prop="old_department_name" label="原系部名称" align="center"></el-table-column>
					<el-table-column prop="old_specialty_name" label="原专业名称" align="center"></el-table-column>
					<el-table-column prop="department_name" label="系部名称" align="center"></el-table-column>
					<el-table-column prop="specialty_name" label="专业名称" align="center"></el-table-column>
				</el-table>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		List: {
			type: Array,
			default: []
		},
		num:{
			type:Number,
			default:0
		}
	},
	data() {
		return {
			
		};
	},
	mounted() {
		
	},
	methods: {
		
	}
};
</script>

<style lang="scss">
#majorLog{
	.contents {
		background-color: #ffffff;
		.head {
			padding: 20px;
		}
		.table{
			padding: 20px;
			.el-table th > .cell,
			.el-table .cell {
				overflow: hidden; /*超出部分隐藏*/
				white-space: nowrap; /*不换行*/
				text-overflow: ellipsis; /*超出部分文字以...显示*/
			}
			.el-table thead {
				background: #fafafa !important;
			}
			.el-table th {
				padding: 15px 5px !important;
				background: #fafafa !important;
			}
			.el-table::before {
				left: 0;
				bottom: 0;
				width: 100%;
				height: 0;
			}
		}
	}
}

</style>
