<template>
	<div style="padding-top: 20px;" id="student_sche">
		<div class="contents">
      <v-weburl-iframe :url="`teacher/table?student_id=${base.id}&hide_week=1`" />
		</div>
	</div>
</template>

<script>
import VWeburlIframe from "@/components/weburl-iframe";
export default {
  components: {VWeburlIframe},
  props: {
		base:{
			type:Object,
			default:{}
		}
	},
};
</script>

<style lang="scss">
#student_sche{
	.contents {
		background-color: #ffffff;
    height: 600px;
	}
}
</style>
